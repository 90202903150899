<template>
    <div>
        <modal :show="showEditModal" @hide="closeEditModal">
            <h2 slot="header">Edit account</h2>
            <div class="p-4" slot="body">
                <div class="mb-4 text-lg">
                    Editing user:
                    <span class="text-gray-800" v-text="sftpAccount.username"></span>
                </div>
                <div class="mb-4">
                    <button @click="initReset" class="w-full btn-primary">
                        <span v-if="! resetInitiated">{{ $t('reset_password') }}</span>
                        <span v-else>{{ $t('reset_password_confirmation') }}</span>
                    </button>
                    <p class="text-red-500" v-if="resetInitiated">
                        <small>{{ $t('reset_password_help') }}</small>
                    </p>
                    <div class="p-4 my-2 text-green-800 bg-green-100" v-if="sftpPassword">
                        <p>{{ $t('new_sftp_password_set') }}</p>
                        <p>
                            {{ $t('state_new_sftp_password') }}
                            <code
                                class="px-1 ml-1 text-base text-red-500 bg-gray-200"
                            >{{ sftpPassword }}</code>
                        </p>
                        <p>
                            <small>{{ $t('new_sftp_info') }}</small>
                        </p>
                    </div>
                </div>

                <button @click="initDelete" class="w-full btn-red">
                    <span v-if="! deleteInitiated">{{ $t('delete_account') }}</span>
                    <span v-else>{{ $t('delete_sftp_account_confirmation') }}</span>
                </button>
            </div>

            <div class="flex w-full" slot="footer">
                <button @click="closeEditModal" class="ml-auto mr-4 text-sm text-red-500">Close</button>
            </div>
        </modal>
        <modal :show="showCreateModal" @hide="closeCreateModal">
            <h2 slot="header">Create account</h2>
            <div class="p-4" slot="body">
                <modal-field class="mb-4" label="Username" required>
                    <input
                        :disabled="accountCreated"
                        placeholder="Provide a username"
                        type="text"
                        v-model="newAccount.username"
                    />
                </modal-field>
                <modal-field class="mb-4" label="Description" required>
                    <input
                        :disabled="accountCreated"
                        placeholder="Describe the user"
                        type="text"
                        v-model="newAccount.description"
                    />
                </modal-field>
                <div class="p-4 my-2 text-green-800 bg-green-100" v-if="newAccount.password">
                    <p>{{ $t('sftp_new_account_created') }}</p>
                    <p>
                        {{ $t('sftp_new_password') }}
                        <code
                            class="px-1 ml-1 text-base text-red-500 bg-gray-200"
                        >{{ newAccount.password }}</code>
                    </p>
                    <p>
                        <small>{{ $t('new_sftp_info') }}</small>
                    </p>
                </div>
                <pre>{{ errors }}</pre>
            </div>
            <div class="flex w-full" slot="footer">
                <button @click="closeCreateModal" class="ml-auto mr-4 text-sm text-red-500">Close</button>
                <button @click="createAccount" class="btn">Create</button>
            </div>
        </modal>
        <page-header title="SFTP Accounts" />
        <card bodyClass="p-0 -mx-6" class="mt-6" title="SFTP Accounts">
            <div class="flex" slot="tools">
                <button @click="showCreateModal = true">
                    <fa :icon="['fal','plus']" class="mr-1" />Create new account
                </button>
            </div>
            <div class="relative flex items" slot="tools"></div>

            <v-table
                class="admin-table"
                :columns="columns"
                :filter-params="filterParams"
                :loading="loadingOrganizations"
                @row-click="rowClicked"
                :endpoint="$apiUrl.sftp"
                :id="'sftpAccounts'"
                ref="sftpaccounts"
                row-key="id"
                sort-key="name"
                :custom-export="myExport"
            >
                <template slot="cell" slot-scope="{row, col}">{{ row[col.key] }}</template>
            </v-table>
        </card>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import DateRangePicker from "~/components/DateRangePicker";
    import { serializeParams } from "~/components/vtable/utils";
    import zipcelx from "zipcelx";

    export default {
        name: "Organizations",
        components: {
            DateRangePicker
        },
        metaInfo() {
            return { title: "SFTP Accounts" };
        },
        data: () => ({
            columns: [
                {
                    title: "Username",
                    key: "username",
                    toggled: true,
                    width: "w-32"
                },
                {
                    title: "Description",
                    key: "description",
                    toggled: true,
                    width: "w-24"
                },
                {
                    title: "Path",
                    key: "path",
                    toggled: true,
                    width: "w-24"
                },
                {
                    title: "Last updated",
                    key: "updated_at",
                    toggled: true,
                    width: "w-24"
                },
                {
                    title: "Created at",
                    key: "updated_at",
                    toggled: true,
                    width: "w-24"
                }
            ],
            loadingOrganizations: false,
            filterParams: {},
            savingQuery: false,
            showEditModal: false,
            sftpAccount: {},
            resetInitiated: false,
            deleteInitiated: false,
            sftpPassword: "",
            showCreateModal: false,
            accountCreated: false,
            newAccount: {
                username: "",
                description: "",
                password: ""
            },
            errors: {}
        }),
        methods: {
            myExport(table) {
                let filename = "price_leadtime" + new Date().toLocaleDateString();
                let exportRows = [];
                let exportRow = [];

                table.toggledColumns.forEach(column => {
                    exportRow.push({
                        value: column.title,
                        type: "string"
                    });
                });
                exportRows.push(exportRow);

                table.rows.forEach(tableRow => {
                    let exportRow = [];

                    table.toggledColumns.forEach(column => {
                        let cell = tableRow[column.key];
                        let type = "string";

                        if (column.key == "total_cost") {
                            type = "number";
                        }
                        exportRow.push({
                            value: cell,
                            type: type
                        });
                    });
                    exportRows.push(exportRow);
                });

                const config = {
                    filename: filename,
                    sheet: {
                        data: exportRows
                    }
                };
                zipcelx(config);
            },
            rowClicked(row) {
                this.sftpAccount = row;
                this.showEditModal = true;
            },
            onClose() {
                this.toggleTableTools = false;
            },
            rangeChanged(range) {
                this.filters.range = range;
            },
            refreshTable() {
                this.$refs.sftpaccounts.table.fetchRows();
            },
            async fetchFilterOptions() {
                const { data } = await axios.get(this.$apiUrl.sftp);

                let response = data.data;
                let keys = Object.keys(this.options);

                keys.forEach(option => {
                    if (response[option]) {
                        this.options[option] = response[option];
                    }
                });
            },
            initReset() {
                if (!this.resetInitiated) {
                    this.resetInitiated = true;
                    return;
                }

                this.resetPassword();
            },
            initDelete() {
                if (!this.deleteInitiated) {
                    this.deleteInitiated = true;
                    return;
                }

                this.deleteAccount();
            },
            closeEditModal() {
                this.sftpPassword = "";
                this.resetInitiated = false;
                this.deleteInitiated = false;
                this.showEditModal = false;
            },
            closeCreateModal() {
                this.showCreateModal = false;
                this.newAccount.username = "";
                this.newAccount.description = "";
                this.newAccount.password = "";
                this.accountCreated = false;
                this.resetErrors();
            },
            resetErrors() {
                this.errors = {};
            },
            async createAccount() {
                try {
                    let { data } = await axios.post(
                        this.$apiUrl.sftp,
                        this.newAccount
                    );
                    this.newAccount.password = data.meta.sftp_password;
                    this.accountCreated = true;
                    this.refreshTable();
                    this.resetErrors();
                } catch (error) {
                    this.errors = error.response.data;
                    console.log(this.errors);
                }
            },
            async resetPassword() {
                try {
                    let { data } = await axios.patch(
                        `${this.$apiUrl.sftp}/` + this.sftpAccount.id
                    );
                    this.sftpPassword = data.meta.sftp_password;
                    this.resetInitiated = false;
                    console.log(data);
                } catch (error) {
                    console.log(error);
                }
            },
            async deleteAccount() {
                try {
                    await axios.delete(`${this.$apiUrl.sftp}/` + this.sftpAccount.id);
                    this.closeEditModal();
                    this.refreshTable();
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };
</script>
